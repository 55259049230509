/* ==========================================================================
   Messages
   ========================================================================== */

.messages {
  margin: 1em auto 2em;
  padding: 1.5em 2em 1.5em 4.5em;
  border-width: 1px;
  border-style: solid;
  border-radius: .25em;
  background-position: 1.5em 1.25em;
  background-repeat: no-repeat;

  &.status {
    color: darken($color-status, 15%);
    background-color: lighten($color-status, 50%);
    border-color: lighten($color-status, 30%);
    @include svg-bg('message-status');
  }
  &.warning {
    color: darken($color-warning, 25%);
    background-color: lighten($color-warning, 30%);
    border-color: $color-warning;
    @include svg-bg('message-warning');
  }
  &.error {
    color: darken($color-error, 10%);
    background-color: lighten($color-error, 35%);
    border-color: darken($color-error, 5%);
    @include svg-bg('message-error');
  }
}