/* ==========================================================================
   Fields
   ========================================================================== */

/* ==========================================================================
   Default Field Label Styles
   ========================================================================== */

.inline { 
  display: inline;
  padding: 0;

  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; 
  }
}

// Display inline list fields as comma separated list. 
.inline[class^=field] {
  li {
    padding: 0;
    &:after {
      content: ', ';
    }
    &:last-child:after {
      content: '';
    }
  }
}

%inline-title {
  margin: 0;
  padding: 0 0.25em 0 0;
  font-size: em(14);
  text-transform: uppercase;
  letter-spacing: .04em;
  display: inline-block;
}

span.field-label, .field-label--inline { /* The inline field label used by the Fences module */
  @extend %inline-title;
}

.field-label--inline--wrapper {
  display: block;
  margin-bottom: 1em;
}

.field-label {
  margin: 0 0 em(10, 30);
  font-size: em(30);
  line-height: (38/30);
  font-weight: $font-weight-bold;
}