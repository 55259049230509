// ==========================================================================
// 
// Banner Styles
// 
// ==========================================================================


.block--page-banner {
  height: 400px;
  @include breakpoint($bp-wide) {
    height: 460px;
  }
}

.banner-bg-image {
  div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

.block--page-header {
  padding-top: em(60);
  padding-bottom: em(30);
  background-image: linear-gradient(transparent, rgba(0,0,0,.7));

  .block__content {
    color: $white;

    > :last-child {
      margin-bottom: 0;
    }
  }

  .page__title {
    margin: 0 0 em(15,48);
    font-size: em(48);
    line-height: 1;
    font-weight: $font-weight-heavy;
  }

  .page__subtitle {
    font-size: em(20);
    font-style: italic;
  }
}

