// ==========================================================================
// 
// Header & Navigation Region Layout
// 
// ==========================================================================


.region-header-inner,
.region-navigation-inner {
  @include clearfix;
  @extend %container-outer;
  position: relative;
}

.region-header {
  padding-top: em(15);
  padding-bottom: em(15);
  background-image: image('headerbg@2x.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right 10% top;
}

.region-navigation {
  // display navigation region over banner
  // class added in template.php preprocess_html
  .with-banner & {
    position: absolute; 
    z-index: 10;
    width: 100%;
  }
}

.menu--booking-menu {
  position: absolute;
  right: 0;
  top: 0;
  @include breakpoint($bp-nav-open) {
    top: -110px;
  }
}