// ==========================================================================
// Helper Classes
// ==========================================================================


/* ==========================================================================
   Clearfix
   ========================================================================== */

%clearfix {
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}

/* ==========================================================================
   Outer Content Container
   ========================================================================== */

%container-outer {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1000px;
}

/* ==========================================================================
   Buttons
   ========================================================================== */

%button {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  border: none;
  vertical-align: baseline;
  font-size: em(12);
  font-weight: $font-weight-bold;
  line-height: (15/12);
  padding: em(10,12) em(25,12);
  border-radius: 3px;
}

%button--orange {
  @extend %button;
  color: $white;
  background-color: $orange;
  &:hover,
  &:focus {
    color: $white;
    background-color: darken($orange, 3%);
  }
}

%button--dark {
  @extend %button;
  color: $white;
  background-color: $green-dark;
  &:hover,
  &:focus {
    color: $white;
    background-color: darken($green-dark, 3%);
  }
}

/* ==========================================================================
   Arrow Links
   ========================================================================== */

%arrow-link {
  display: inline-block;
  position: relative; // context for arrow

  // Shift arrow to right on hover,focus
  &:hover,
  &:focus {
    &:after {
      right: -1.6em;
    }
  }
  // Place arrow at end of content
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: .05em; 
    right: -1.3em;
    height: 1em;
    width: 1em;
    background-size: contain;
    background-repeat: no-repeat;
    @include svg-bg('icon-arrow');
    transition: right, 0.2s, ease-in-out, 0s;
  }
}



// ==========================================================================
// Image replacement
// ==========================================================================

%ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;/* IE 6/7 fallback */
  *text-indent: -9999px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
  }
}

%no-bullets {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}


// Hide from both screenreaders and browsers: h5bp.com/u

%hidden {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v

%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout

%invisible {
  visibility: hidden;
}
