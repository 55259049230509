/* ==========================================================================
   Navigation
   ========================================================================== */

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu__link {
  display: block;
  text-underline: none;
}


/**
 * Defaults
 */

.region-navigation {
  padding-top: em(7.5);
  padding-bottom: em(7.5);

  .menu__link,
  .menu-trigger { 
    color: $tan-extra-light;
    font-weight: $font-weight-bold;
    font-size: em(13);
    line-height: 1;
    padding: em(11,13) em(15,13);
    border-radius: 3px;
  }
}

/* ==========================================================================
   Main Menu
   ========================================================================== */

.menu--main-menu {
  .js & {
    @include breakpoint($bp-nav-collapsed) {
      margin-top: .5em;
      display: none;
    }
  }

  @include breakpoint($bp-nav-open) {
    display: block !important; // in case closed on narrow menu
  }
  .menu {
    @include breakpoint($bp-nav-open) {
      text-align: center;
    }
  }
  .menu__item {
    position: relative; // context for toggle sub menu button (collapsed) and submenu (open)

    @include breakpoint($bp-nav-collapsed) {
      padding: .25em 0;
      border-top: 1px solid rgba($green-dark, .3);
    }
    @include breakpoint($bp-nav-open) {
      display: inline-block;
    }

    // Second Level Menu Items
    &.level-2 {
      border: 0;
    }
  }

  // Parent Menu Items
  .is-parent {
    @include breakpoint($bp-nav-open) {
      .menu {
        display: block !important; // may have .is-hidden class applied still
        position: absolute;
        z-index: 10;
        top: -9999px;
        left: 50%;
        margin-left: -4em;
        width: 8em;
        border-radius: 3px;
        background-color: rgba(135,134,113,.9);
        .menu__item {
          display: block;
        }
      }
      &.hover,
      &:hover {
        > .menu__link {
          background-color: rgba(27,24,24,.1);
        }
        .menu {
          top: 100%;
        }
      }
    }
  }

  .menu__link {
    transition: background-color 0.3s ease-out;

    &:hover,
    &:focus {
      @include breakpoint($bp-nav-open) {
        background-color: rgba(27,24,24,.1);
      }
    }

    &.active,
    &.active-trail {
      @include breakpoint($bp-nav-open) {
        background-color: rgba(27,24,24,.2);
      }
    }
  }

  // Second Level Menu Links
  .level-2 .menu__link {
    font-weight: normal;
  }
}

/**
 * Main Menu Toggle
 */
.menu-trigger {
  display: inline-block;
  text-decoration: none;
  background-color: rgba(27,24,24,.2);
  border: 0;
  @include breakpoint($bp-nav-open) {
    display: none;
  }
}

/**
 * Submenu Toggle
 */
.toggle-submenu {
  margin: 0;
  padding: 0;
  appearance: none;
  border: 0;
  display: block;
  position: absolute;
  top: .25em;
  right: 0;
  height: 2.15em;
  width: 2.15em;
  text-indent: -9999px;
  overflow: hidden;
  background-color: rgba(27,24,24,.2);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  @include svg-bg('icon-expand');

  &.is-open {
    @include svg-bg('icon-collapse');
    background-color: $color-link;
  }

  @include breakpoint($bp-nav-open) {
    display: none;
  }
}

/* ==========================================================================
   Booking Menu
   ========================================================================== */

.menu--booking-menu {

  .menu {
    @include breakpoint($bp-nav-collapsed) {
      @include clearfix;
    }
    @include breakpoint($bp-nav-open) {
      text-align: center;
    }
  }

  .menu__item {
    @include breakpoint($bp-nav-collapsed) {
      float: left;
      margin-left: 5px;
    }
  }

  .menu__item.menu-mlid-420 {
    .menu__link {
      @include breakpoint($bp-nav-collapsed) {
        background-color: rgba(27,24,24,.2);
        @include svg-bg('icon-phone');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        overflow: hidden;
        text-indent: -9999px;
        width: 40px;
      }
      @include breakpoint($bp-nav-open) {
        padding: 0 0 .5em;
        color: $gray-dark;
        font-size: em(16);
        font-weight: $font-weight-heavy;
      }
    }
  }

  .menu__item.menu-mlid-812 {
    .menu__link {
      @include breakpoint($bp-nav-collapsed) {
        background-color: rgba(27,24,24,.2);
        background-image: url(../images/icon-mail.png);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        overflow: hidden;
        text-indent: -9999px;
        width: 40px;
      }
      @include breakpoint($bp-nav-open) {
        padding: 0 0 .5em;
        color: $gray-dark;
        font-size: em(16);
        font-weight: $font-weight-heavy;
      }
    }
  }

  .menu__item:last-child {
    .menu__link {
      text-align: center;
      background-color: $orange;
      display: inline-block;
      @include breakpoint($bp-nav-collapsed) {
        width: 110px;
      }
    }
  }
}



/* ==========================================================================
   Breadcrumb
   ========================================================================== */

.breadcrumb {
  font-size: em(11);
  line-height: 1;
  color: $color-text-light;

  ol {
    margin: em(20,11) 0;;
    padding: 0;
    list-style: none;
  }
  li {
    display: inline;
    margin: 0;
    padding: 0;

    // Home Link
    &:first-child {
      a {
        display: inline-block;
        padding: 0;
        width: 11px;
        overflow: hidden;
        text-indent: -9999px;
        @include svg-bg('icon-home');
        background-position: 50% bottom;
        background-repeat: no-repeat;
        background-size: auto 90%;
      }
    }
  }
  a {
    color: $color-text-light;
    font-weight: bold;
  }
}

/* ==========================================================================
   Sidebar Links (field on page content type)
   ========================================================================== */

.block--sidebar-links {
  
  .field-sidebar-links {
    list-style: none;
    padding: 0;

    li {
      margin: 0 0 .5em;
      font-size: em(18);
    }

    a {
      @extend %arrow-link;
    }
  }
}

