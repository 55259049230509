/* ==========================================================================
   Forms
   ========================================================================== */


/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin-bottom: 1em;

  /* Highlight the form elements that caused a form submission error. */
  input.error,
  textarea.error,
  select.error {
    border: 1px solid #c00;
  }

  /* The descriptive help text (separate from the label). */
  .description {
    margin: .25em 0 0;
    font-size: 0.625em;
    font-style: italic;
    color: lighten($color-text, 10%);
  }
}

/**
 * Form Labels
 */

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00;
}

label {
  display: block;
  font-weight: bold;
}

/**
 * Field Groups & Fieldsets
 */
.fieldgroup,
fieldset {
  margin: 0 0 1.5em;
  padding: 0;
  border: 0;

  .description {
    margin: .25em 0 .5em;
    font-size: .75em;
  }
}

/**
 * Text Fields and Textareas
 */
.form-type-textfield,
.form-type-password {
  .description {
    margin: .125em 0 0;
  }
}
.form-text,
.form-textarea {
  max-width: 100%;
  font-size: em(12);
  line-height: (15/12);
  padding: em(9,12) em(10,12);
  border-radius: 3px;
  border: 1px solid $color-border;
  background-color: $color-background-tint;

  &:focus {
    background-color: $white;
  }
}

/**
 * Radio Buttons & Checkboxes
 */

// single
.form-type-checkbox.form-item-checkbox {
  margin-bottom: 1em;
}

// Multiple checkboxes or radios
.form-checkboxes,
.form-radios {

}

// Single Checkbox or Radio 
.form-type-checkbox,
.form-type-radio {
  margin-bottom: .25em;

  > label {
    margin-bottom: .25em;
  }
  
  .option {
    display: inline-block;
    font-weight: normal;
    font-size: em(12);
    line-height: (15/12);
  }
}

/**
 * Select Elements 
 */
.form-select {
  max-width: 100%;
  font-size: em(12);
  line-height: (15/12);
  padding: em(10,12);
  border-radius: 3px;
  border: 1px solid $color-border;
  background-color: $color-background-tint;
}

/**
 * File Inputs
 */
.form-file {
  max-width: 100%;
  font-size: em(12);
  line-height: (15/12);
  padding: em(7,12);
  border-radius: 3px;
  border: 1px solid $color-border;
  background-color: $color-background-tint;
}


/**
 * Submit Buttons
 */
.form-submit {
  @extend %button--orange;
}
