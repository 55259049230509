// ==========================================================================
// 
// Header Styles
// 
// ==========================================================================

.header__site-name {
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1;
  display: block;
  max-width: 443px;
  text-indent: -9999px;
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: percentage(116/443);
  }
}

.header__site-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-image: image('wilder-farm-inn-logo.png');

  // high res displays
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: image('wilder-farm-inn-logo@2x.png');
  }
}

.header__site-slogan {
  @include element-invisible;
  display: block;
}
