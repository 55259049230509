/* ==========================================================================
   Blocks
   ========================================================================== */


/**
 * Home Photo Block
 */

.block--home-intro {
  padding-top: em(40);
  padding-bottom: em(40);
  background-color: $green-dark;
  color: $tan-light;

  .block__content {
    position: relative; // context for lines
    margin: 0 auto;
    padding-top: 1em;
    padding-bottom: 1em;
    width: 90%;
    max-width: em(620,20);
    font-size: em(20);
    line-height: 1.5;
    text-align: center;

    > :last-child {
      margin-bottom: 0;
    }

    // lines
    &:before,
    &:after {
      content: '';
      display: block;
      width: 180px;
      height: 1px;
      background-color: $tan-light;
      position: absolute;
      left: 50%;
      margin-left: -90px;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
  }
}


/**
 * Facebook Embed Block
 */

// Don't allow it to break container
.fb_iframe_widget {
  max-width: 100%;
  overflow: hidden;
}

/**
 * Home Slogan Block
 */
.block--home-slogan {
  padding-top: em(80);
  padding-bottom: em(80);
  background-color: $color-background-tint;

  .block__content {
    margin: 0 auto;
    width: 90%;
    font-size: em(30);
    line-height: 1.5;
    font-style: italic;
    text-align: center;

    > :last-child {
      margin-bottom: 0;
    }
  }
}


/**
 * Leaflet Map
 */

img.leaflet-marker-icon {
  padding: 2px;
  width: 36px !important;
  height: 36px !important;
  border-radius: 20%;
  background-color: rgba(255,255,255,0.7);
}

/**
 * Hostfully Widgets
 */

.block--hostfully {
  .orbirental-calendar-widget {
    width: 100% !important;
    border: 1px solid #EEEEEE !important;
    .orbirental-calendar-widget-nav {
      img {
        cursor: pointer;
      }
    } 
    .calendar {
      font-family: $font-family-sans !important;
    }
    .propertyName {
      display: none !important;
    }
  }

  .leadWidget > form.orbirental-capture-widget-form {
    font-family: $font-family-sans !important;
  }
}