/* ==========================================================================
   Node Displays
   group by node type
   ========================================================================== */



/* ==========================================================================
   Room Nodes
   ========================================================================== */

.node-type-room,
.node-type-whole-house-rental {

  .region-content {
    padding: em(50) em(40);
    background-color: $color-background-tint;
    border: 1px solid $color-border;

    .field-body {
      font-size: em(20);
      line-height: (30/24);
      border-top: 1px solid $color-border;
      margin-top: 2em;
      padding-top: 1em;
    }

    // Section / field labels
    .field-label,
    .group-rates h3 {
      margin: em(25,12) 0;
      padding-top: em(25,12);
      font-size: em(12);
      line-height: 1.25;
      font-weight: $font-weight-heavy;
      text-transform: uppercase;
      letter-spacing: .2em;
      border-top: 1px solid $color-border;
    }
  }

  /**
   * Images
   */
  .field-room-images {
    @include clearfix;
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
      margin: 0 5px 5px 0;
      float: left;
      width: 100px;
    }

    a {
      display: block;
      transition: opacity .3s ease-out;
      
      &:hover,
      &:focus {
        opacity: .7;
      }
    }

    img {
      padding: 5px;
      border: 1px solid $color-border;
    }
  }

  /**
   * Features
   */
  .field-room-features {
    list-style: none;
    margin: 0 0 -10px;
    padding: 0;
    @include clearfix;

    li {
      display: block;
      margin: 0 5% em(10) 0;
      padding-left: 1.25em;
      position: relative;
      @include breakpoint($bp-middle-and-wide) {
        float: left;
        width: 45%;
      }

      &:before {
        content:  "\2713 ";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  /**
   * Rates
   */
  .list--rates {
    list-style: none;
    margin: 0 0 em(10);
    padding: 0;
    @include breakpoint($bp-middle-and-wide) {
      display: flex;
    }
  }
  .rate {
    @include breakpoint($bp-middle-and-wide) {
      width: 33.3%;
    }
    &:nth-child(2){
      @include breakpoint($bp-narrow) {
        padding: 1em 0;
        margin: 1em 0;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
      }
      @include breakpoint($bp-middle-and-wide) {
        padding: 0 1em;
        margin: 0 1em;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
      }
    }
  }
  .rate__title {
    margin: 0 0 .25em;
    font-size: em(16);
    font-weight: normal;
  }
  .rate__value {
    margin: 0 0 .25em;
    font-size: em(20);
    font-weight: bold;

    &:after {
      content: '*';
    }
  }
  .rate__info {
    margin: 0;
    font-size: em(10);
    color: $color-text-light;
  }
  .rates-disclaimer {
    display: block;
    margin: 0 0 em(15,10);
    font-size: em(10);
    font-style: italic;
  }
  .field-early-check-in-rates {
    font-size: em(12);
  }
}

.views-view-grid td {
  padding: 1px !important;
  border: none;
}

.view-truplace-button {
    text-align: center;
    @include breakpoint($bp-middle-and-wide) {
      font-size: 1.25em;
  }
}