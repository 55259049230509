// ==========================================================================
// 
// FOOTER Section Styles
// 
// ==========================================================================

/* ==========================================================================
   Footer First Region
   ========================================================================== */

.region-footer-first {
  padding-top: em(40);
  padding-bottom: em(30);

  @include breakpoint($bp-footer-wide){
    padding-top: em(80);
    padding-bottom: em(40);
  }

  .block {
    margin-bottom: em(20);
    @include breakpoint($bp-footer-wide){
      margin-bottom: em(30);
    }
  }

  // Default Block Title
  .block__title {
    margin: 0 0 .5em;
    font-size: em(24);
    line-height: 1.1;
    font-weight: $font-weight-heavy;
  }
}

/**
 * Footer Logo
 */
.footer-site-name {
  margin: 0 0 em(20);
  font-size: 1em;
  line-height: 1;

  a {
    display: block;
    width: 167px;
    height: 192px;
    text-indent: -9999px;
    overflow: hidden;
    @include svg-bg('logo-offwhite');
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity .3s ease-out;

    &:hover,
    &:focus {
      opacity: .7;
    }
  }
}

/**
 * Top Level Links
 */

.block--footer-main-menu {

  .menu {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint($bp-wide) {
      justify-content: space-between;
    }
  }

  .menu__item:not(:last-child) {
    margin-right: 1.5em;
  }

  .menu__link {
    padding: .5em 0;
    display: block;
    font-size: em(13);
    font-weight: $font-weight-heavy;
    transition: opacity .3s ease-out;

    &:hover,
    &:focus {
      opacity: .7;
    }
  }
}

/**
 * Social Menu
 */
// Apply to matching url links
%social-icon-link {
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  overflow: hideen;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: opacity .3s ease-out;

  &:hover,
  &:focus {
    opacity: .7;
  }
}

.block--social-links {

  .menu__item {
    display: inline-block;
    margin: 0 .125em;
  }

  .menu__link {
    // Social Icons
    &[href*="facebook.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-facebook');
    }
    &[href*="twitter.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-twitter');
    }
    &[href*="youtube.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-youtube');
    }
    &[href*="instagram.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-instagram');
    }
  }
}

/**
 * Newsletter Signup Form
 */
.block--constant-contact {

  .ctct-custom-form {
    display: flex;
    max-width: em(300);
  }

  .ctct-custom-form p {
    margin: 0;
    flex-grow: 1;
    padding-right: .25em;
  }

  label.ctct-form-required {
    @include element-invisible;
  }

  input[type='text'], input[type='email'] {
    width: 100%;
    padding: em(11.5,13) em(10,13);
    background-color: transparent;
    border: 2px solid $tan-extra-light;
    border-radius: 3px;
    font-size: em(13);
    line-height: 1;

    &::placeholder {
      color: rgba($tan-extra-light, .8);
    }
  }

  .ctct-button {
    padding: em(13,14) em(15,14);
    background-color: $tan-extra-light;
    border-radius: 3px;
    border: 0;
    color: $color-text;
    font-size: em(14);
    line-height: 1;
    font-weight: $font-weight-heavy;
  }
}


// Disclaimer text
.ctct-form-footer {
  @include element-invisible;
}

/* ==========================================================================
   Footer Second Region
   ========================================================================== */

.region-footer-second {
  padding-top: em(20);
  padding-bottom: em(10);
}

/**
 * Booking Menu Block
 */

.block--footer-booking {

  .block__title {
    float: left;
    margin: 0 1em .25em 0;
    font-size: em(24);
    line-height: 1.4;
    font-weight: $font-weight-heavy;
    font-style: italic;
  }

  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu__item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .5em;
  }

  .menu__link {
    display: block;
    font-weight: bold;
  }

  .menu__item:last-child {
    .menu__link {
      @extend %button--orange;
    }
  }
}

/* ==========================================================================
   Footer Third Region
   ========================================================================== */

.region-footer-third {
  padding-top: em(20);
  padding-bottom: em(10);
}

.region-footer-third-inner {
  font-size: em(10);

  @include breakpoint($bp-wide) {
    font-size: em(11);
  }
}

.copyright {
  font-weight: bold;
}

