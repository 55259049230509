/* ==========================================================================
   Page Regions
   define colors, backgrounds for main page wrappers
   ========================================================================== */

html {
  background-color: $color-background;
  color: $color-text;
}

/**
 * Header
 */
.region-header {

}

/**
 * Navigation
 */
.region-navigation {
  background-color: rgb(135,134,113);
  background-color: rgba(135,134,113,.9);
}

/**
 * Banner
 */
.region-banner {
  background-color: $tan-dark;
  border-bottom: 2px solid $tan-dark;
}

/**
 * Main Content Region
 */
.region-main-inner {
  padding-bottom: em(80);
}

.region-content {
  margin-bottom: em(30);
}

/**
 * Footer
 */
.region-footer {
  color: $color-background;
  background-color: $green-dark;
  background-image: image('footer-bg@2x.jpg');
  background-size: cover;
  background-position: 50% top;

  a {
    color: inherit;
  }
}

.region-footer-second {
  background-color: rgba($black, .3);
}

.region-footer-third {
  background-color: rgba($black, .45);
}

