// ==========================================================================
// Mixins
// ==========================================================================

/**
 * Clearfix
 */

@mixin clearfix {
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}

/**
 * RGBA Fallback
 * @param {String} $color
 * @param {String} $percent
 * @example scss
 * .foobar { @include rgba-bg(#f37,.9) { ... } }
 */

@mixin rgba-bg($color, $percent) {
  background-color: $color;
  background-color: rgba($color, $percent);
}

/**
 * SVG Bckground Images with png fallback
 * @param {String} $filename
 * @example scss
 * .foobar { @include svg-bg('image') { ... } }
 */

// modernizr class and Sass content inject for fallback code
@mixin no-svg { .no-svg & { @content } }
 
// mixin that requires filename without path or extension and optional extension override
@mixin svg-bg($filename, $extension: '.png') {
  background-image: image($filename + '.svg');
  @include no-svg { background-image: image($filename + $extension); }
}


/**
 * Vertical Center
 * @link http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
 * @link http://www.useragentman.com/IETransformsTranslator/
 * @example scss
 * .foobar { @include vertical-center() { ... } }
 */

@mixin no-transform-ie { .lt-ie9 & { @content } }
@mixin vertical-center {
  position: relative;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
  @include no-transform-ie {
   /* IE8 - must be on one line, unfortunately */ 
   -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
   /* IE6 and 7 */ 
   filter: progid:DXImageTransform.Microsoft.Matrix(
            M11=1,
            M12=0,
            M21=0,
            M22=1,
            SizingMethod='auto expand');
   margin-left: -50%;
   margin-top: -50%;
  }
}


/**
 * Makes an element visually hidden, but accessible.
 * @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/**
 * Turns off the element-invisible effect.
 */
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/**
 * Makes an element visually hidden by default, but visible when focused.
 */
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

/**
 * Font Helpers
 */
@mixin webfont {
  & {
    font-family: $font-family-fallback;
  }
  #{$fonts-loaded-class} & {
    font-family: $font-family-default;
  }
}

@mixin webfont-sans {
  & {
    font-family: $font-family-fallback-sans;
  }
  #{$fonts-loaded-class} & {
    font-family: $font-family-sans;
  }
}

@mixin webfont-serif {
  & {
    font-family: $font-family-fallback-serif;
  }
  #{$fonts-loaded-class} & {
    font-family: $font-family-serif;
  }
}

@mixin webfont-condensed {
  & {
    font-family: $font-family-fallback-condensed;
  }
  #{$fonts-loaded-class} & {
    font-family: $font-family-condensed;
  }
}


/**
 * Layout Debug Helpers
 */
@mixin debuglayout {
  outline: 1px dotted red;
}