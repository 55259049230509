/* ==========================================================================
   Default Link Styles
   ========================================================================== */


a {
  color: $color-link;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }
}