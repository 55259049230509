/* ==========================================================================
   Teaser Styles
   ========================================================================== */



/* ==========================================================================
   Home Featured Blocks
   ========================================================================== */

.list--featured-links {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  @include breakpoint($bp-middle-and-wide) {
    flex-wrap: nowrap;
  }
}

.featured-link {
  flex-grow: 1;
}

.featured-link__link {
  display: block;
  position: relative;
  color: $white;
  background-color: $gray-dark;

  &:hover,
  &:focus {
    img {
      opacity: .5;
    }
    color: $white;
  }

  img {
    width: 100%;
    opacity: .75;
    transition: opacity .3s ease-out;
  }
}

.featured-link__title {
  margin: 0;
  padding: 1em;
  display: flex; // allows us to vertical center span
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: $font-weight-heavy;
  text-align: center;

  span {
    display: inline-block;
    margin: 0;
    padding: .75em 1em;
    border: 2px solid rgba($white, .5);
    border-radius: 3px;
  }
}


/* ==========================================================================
   Rooms
   ========================================================================== */

/**
 * Room Teasers
 */
.list--room-teasers {
  list-style: none;
  padding: 0;
  margin: 0;
}

.room-teaser {
  display: inline-block;
  margin: 0 5px 5px 0;
  max-width: 100%;
  @include breakpoint($bp-middle-and-wide) {
    max-width: 48%;
  }
  @include breakpoint($bp-wide) {
    max-width: 32%;
  }
}

.room-teaser__link {
  position: relative;
  display: block;
  color: $white;
  background-color: $gray-dark;

  &:focus,
  &:hover {
    color: $white;

    img {
      opacity: .5;
    }
  }

  img {
    width: 100%;
    display: block;
    transition: opacity .3s ease-out;
  }
}

.room-teaser__title {
  margin: 0;
  padding: em(40,18) em(20,18) em(20,18);
  background-image: linear-gradient(transparent, rgba(0,0,0,.7));
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: em(18);
  font-weight: $font-weight-heavy;
  line-height: 1;
  letter-spacing: .04em;
  color: $white;
}


/**
 * Room Links
 */
.list--room-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.room-link {
  display: inline-block;
  margin: 0 5px 5px 0;
  max-width: 100%;
}

.room-link__link {
  position: relative;
  display: block;
  color: $white;
  background-color: $gray-dark;

  &:focus,
  &:hover {
    color: $white;

    img {
      opacity: .5;
    }
  }

  img {
    width: 100%;
    display: block;
    transition: opacity .3s ease-out;
  }
}

.room-link__title {
  margin: 0;
  padding: em(20,13) em(10,13) em(10,13);
  background-image: linear-gradient(transparent, rgba(0,0,0,.7));
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: em(13);
  font-weight: $font-weight-heavy;
  line-height: 1;
  letter-spacing: .04em;
  color: $white;
}

/* ==========================================================================
   TripAdvisor Reviews
   ========================================================================== */

.block--tripadvisor {
  padding-top: em(40);
  padding-bottom: em(40);

  .block__content {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;

    @include breakpoint($bp-middle-and-wide) {
      display: flex;
      align-items: center;
    }
  }

  .field-body {
    margin: 0 0 em(20, 28);
    padding: 0;
    font-size: em(28);
    line-height: (35/28);
    font-style: italic;

    > :last-child {
      margin-bottom: 0;
    }
  }
}

.ta-review__header {
  margin: auto;
  max-width: 280px;
  @include breakpoint($bp-narrow) {
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
    border-bottom: 1px solid rgba($green-dark, .2);
  }
  @include breakpoint($bp-middle-and-wide) {
    padding-right: 1.5em;
    margin-right: 1.5em;
    border-right: 1px solid rgba($green-dark, .2);
  }
}

.ta-review--body {
  @include breakpoint($bp-narrow) {
    text-align: center;
  }
}

.ta-review__logo {
  margin: 0;
  a {
    margin: auto;
    display: block;
    overflow: hidden;
    text-indent: -9999px;
    width: 183px;
    height: 120px;
    background-image: image('trip-advisor-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.ta-review__certificate {
  margin: 5px auto 0;
  padding: 5px 0 5px 35px;
  max-width: 150px;
  font-size: em(11);
  line-height: (14/11);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .08em;
  color: $black;
  @include svg-bg('icon-award');
  background-position: left 50%;
  background-repeat: no-repeat;

  strong {
    font-weight: $font-weight-heavy;
  }
}

.review-link {
  margin: 0;
  font-size: em(18);
  font-weight: bold;
  font-style: italic;

  a {
    @extend %arrow-link;
  }
}
