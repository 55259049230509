/* ==========================================================================
   Tabs
   ========================================================================== */

.tabs-primary {
  list-style: none;
  margin: 1.5em 0;
  padding: 0;
  border-bottom: 1px solid $tabs-border;
  font-size: em(13);
  line-height: 1.2;
}

.tabs-primary__tab {
  display: inline-block;
  margin: 0 0 0 .5em;
}

.tabs-primary__tab-link {
  display: block;
  position: relative;
  bottom: -1px;
  padding: .25em .75em .25em;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px 3px 0 0;
  color: $tabs-link-inactive;
  &.is-active {
    color: $tabs-link-active;
    border-color: $tabs-border $tabs-border $color-background;
    &:hover,
    &:focus {
      color: $tabs-link-active;
    }
  }
  &:hover,
  &:focus {
    color: $tabs-link-inactive-hover;
  }
}