/* ==========================================================================
   Media
   ========================================================================== */

/**
 * Images and Captions
 */

img {
  /* Remove border when inside `a` element in IE 8/9. */
  border: 0;

  /* Suppress the space beneath the baseline */
  vertical-align: bottom;

  /* Responsive images */
  max-width: 100%;
  height: auto;
  width: auto;
}


/* Embedded images using WYSIWYG and Picture module */
picture {
  display: block;
  position: relative;
  margin-bottom: 0.5em;
  &[data-picture-align="left"] {
    margin: 0 1.5em 0.5em 0;
    float: left;
  }
  &[data-picture-align="right"] {
    margin: 0 0 0.5em 1.5em;
    float: right;
  }
  &[data-picture-align="center"]  {
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &[data-picture-mapping="full_width"] {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  &[data-picture-mapping="half_width"] {
    max-width: 50%;
  }
  &[data-picture-mapping="quarter_width"] {
    max-width: 50%;
    @include breakpoint($bp-middle-and-wide) {
      max-width: 25%;
    }
  }
  img {
    display: block;
  }
}


/* Photo Captions */
figcaption,
.caption {
  font-size: 0.75em;
  margin: .5em 0;
  font-style: italic;
}

iframe {
  max-width: 100%;
}