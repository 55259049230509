// ==========================================================================
// 
// Main Content Area Layout
// 
// ==========================================================================

.breadcrumb {
  @extend %container-outer;
}


.region-main-inner {
  @include clearfix;

  .not-front & {
    @extend %container-outer;
  }
}

/* ==========================================================================
   Home Page Layout
   ========================================================================== */

.front {
  .sidebars {
    @include clearfix;
    @extend %container-outer;

    .region-sidebar-first {
      @include breakpoint($bp-middle-and-wide) {
        float: left;
        width: 30%;
      }
    }

    .region-sidebar-second {
      @include breakpoint($bp-middle-and-wide) {
        float: right;
        width: 60%;
      }
    }
  }
}

/* ==========================================================================
   Default Inside Layout
   ========================================================================== */

.not-front {
  .region-content {
    @include breakpoint($bp-wide) {
      float: left;
      width: 70%;
    }
  }

  .region-sidebar-first {
    @include breakpoint($bp-wide) {
      float: right;
      width: 25%;
    }
  }

  .region-sidebar-second {
    clear: both;
  }
}

/**
 * Wide Content Layout
 */
.wide-content.not-front {
  .region-content,
  .region-sidebar-first,
  .region-sidebar-second {
    float: none;
    width: 100%;
  }
}




/* ==========================================================================
   Room Node Layout
   ========================================================================== */

.node-type-room { 

  .region-content {
    @include breakpoint($bp-wide) {
      float: left;
      width: 62.5%;
    }
  }

  .region-sidebar-first {
    @include breakpoint($bp-wide) {
      float: right;
      width: 32.5%;
    }
  }

  .region-sidebar-second {
    clear: both;
  }

}