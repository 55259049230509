/* ==========================================================================
   Typography
   ========================================================================== */


/**
 * Root html element
 * (set defaults for root font size and line height)
 */
html {
  font-family: $font-family-fallback; 
  font-size: 100% * ($base-font-size-value / 16); 
  line-height: $base-line-height-ratio;
  // Class added to html when fonts are loaded
  &#{$fonts-loaded-class} {
    font-family: $font-family-default;
  }
}

/**
 * Headers
 */

h1 {
  font-size: em(36);
  line-height: 1.2;
  font-weight: $font-weight-heavy;
}

h2 {
  font-size: em(30);
  line-height: 1.2;
}

h3 {

}

h4 {

}

h5 {

}

h6 {

}

/**
 * Paragraph
 */

p {
  margin: 0 0 1em;
}

/**
 * Lists
 */

ul {
  margin: 0 0 1em;
  padding: 0 0 0 1em;
}

ol {
  margin: 0 0 1em;
  padding: 0 0 0 1em;
}

/**
 * Quotes
 */

blockquote {
  
}