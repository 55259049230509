// ==========================================================================
// 
// Banner Region Layout
// 
// ==========================================================================

.region-banner {
  position: relative;
}

.block--page-header {
  // Position absolute when image block displays
  &:nth-child(2) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .block__content {
    @extend %container-outer;
  }
}
