// ==========================================================================
// 
// Footer Layout Styles
// 
// ==========================================================================

.region-footer {
  .region {
    @include clearfix;
  }
  .region-inner {
    @extend %container-outer;
  }
}

/**
 * First Footer Region
 */
.region-footer-first-inner {
  position: relative;

  @include breakpoint($bp-footer-wide){
    display: flex;
    flex-wrap: wrap;
  }

  .block--footer-main-menu {
    width: 100%; // should take up whole row always
  }

  .block--social-links {
    margin-right: 7.5%;
  }
}


/**
 * Third Footer Region
 */

.region-footer-third-inner {
  // Justify left and right copyright and contact info when there's enough space
  @include breakpoint(630px) {
    display: flex;
    justify-content: space-between;
  }
}


