// ==========================================================================
// 
// Sidebar Region Styles
// 
// ==========================================================================



/* ==========================================================================
   Sidebar Blocks
   ========================================================================== */

.sidebar {

  .block {
    margin-bottom: em(40);
  }

  .block__title {
    margin: 0 0 em(20,24);
    font-size: em(24);
    line-height: (30/24);
    font-weight: $font-weight-heavy;
  }
}