// ==========================================================================
// 
// Flexslider Styles
// 
// ==========================================================================

/* Direction Nav */
// .flex-direction-nav {*height: 0;}
// .flex-direction-nav a  { text-decoration:none; display: block; width: 40px; height: 40px; margin: -20px 0 0; position: absolute; top: 50%; z-index: 10; overflow: hidden; opacity: 0; cursor: pointer; color: rgba(0,0,0,0.8); text-shadow: 1px 1px 0 rgba(255,255,255,0.3); }
// .flex-direction-nav .flex-prev { left: -50px; }
// .flex-direction-nav .flex-next { right: -50px; text-align: right; }
// .flexslider:hover .flex-prev { opacity: 1; left: 0px; }
// .flexslider:hover .flex-next { opacity: 1; right: 0px; }
// .flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover { opacity: 1; }
// .flex-direction-nav .flex-disabled { opacity: 0!important; filter:alpha(opacity=0); cursor: default; }
// .flex-direction-nav a:before  { font-size: 40px; line-height:1; display: inline-block; }
.flex-direction-nav a.flex-next:before  { display: none; }
.flex-direction-nav a.flex-prev:before  { display: none; }

.flex-direction-nav {
  *height: 0;
  
  a {
    text-decoration:none; 
    display: block; 
    width: 28px; 
    height: 66px; 
    margin: -33px 0 0; 
    position: absolute; 
    top: 50%; 
    z-index: 10; 
    color: transparent;
    overflow: hidden; 
    opacity: 0; 
    cursor: pointer; 
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .flex-prev {
    font-size: 0;
    @include svg-bg('arrow-prev');
    left: -76px;
    
    .flexslider:hover & {
      opacity: 1; 
      left: 0;
    }
    &:hover,
    &:focus {
      opacity: 1; 
    }
  }

  .flex-next {
    font-size: 0;
    @include svg-bg('arrow-next');
    right: -76px; 
    text-align: right;
    
    .flexslider:hover & {
      opacity: 1; 
      right: 0;
    }
    &:hover,
    &:focus {
      opacity: 1; 
    }
  }

  .flex-disabled { 
    opacity: 0!important; 
    filter:alpha(opacity=0); 
    cursor: default; 
  }
}

.slides {
  @include clearfix;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: none;
    -webkit-backface-visibility: hidden;
  }

  img {
    width: 100%;
    display: block;
    -moz-user-select: none;
  }
}

// No JS fallback: show first slide
.no-js .slides > li:first-child {
  display: block;
}