// ==========================================================================
// = Global Variables
// ==========================================================================


/**
 * Breakpoint
 * @link https://github.com/at-import/breakpoint
 */
@import '../bower_components/compass-breakpoint/stylesheets/breakpoint';



/**
 * CSS layout debugging
 * @link http://pesticide.io/
 * @type boolean
 */

$pesticide-debug              : false;


// Color Scheme
// =============

// Descriptive colors:
$white                        : #fff;
$black                        : rgb(34,30,32);
$gray-dark                    : rgb(74,74,74);
$tan-light                    : rgb(242,238,225);
$tan-extra-light              : rgb(255,252,242);
$tan-dark                     : rgb(124,123,106);
$green-dark                   : rgb(89,87,75);
$orange                       : rgb(226,154,83);

// Common colors
$color-background             : $tan-light;
$color-background-shade       : $white;
$color-background-tint        : $tan-extra-light;
$color-border                 : rgb(227,223,211);
$color-text                   : $green-dark;
$color-text-light             : $tan-dark;

// Links
$color-link                   : $orange;
$color-link-hover             : $orange;
$color-link-visited           : $orange;

// Selections
$color-selection              : #b3d4fc;

// Messages
$color-status                 : rgb(76,157,42);
$color-warning                : rgb(250,220,59);
$color-error                  : red;

// Tabs
$tabs-link-active: $color-text;
$tabs-link-inactive: $color-link;
$tabs-link-inactive-hover: $color-link-hover;
$tabs-border: #bbb;

/**
 * Base font size in used in _mixins.scss
 * @type number
 */

$base-font-size-value         : 16;
$base-font-size               : $base-font-size-value * 1px ;

/**
 * Base line-height in used in _mixins.scss
 * @type number
 */

$base-line-height-ratio       : (20/16);
$base-line-height             : ( $base-font-size-value * $base-line-height-ratio ) * 1px;


// Font Stacks
// ===========

/**
 * (optional) URL for Google Fonts import
 * @type string
 */

// $google-fonts-url          : 'http://fonts.googleapis.com/css?family=Source+Sans+Pro|Source+Code+Pro';


/**
 * define font stack used for sans-serifs
 * @type string
 */

$font-family-sans             : Muli, sans-serif;
$font-family-fallback-sans    : sans-serif;

/**
 * define font stack used for serifs
 * @type string
 */

$font-family-serif            : serif;
$font-family-fallback-serif   : serif;

/**
 * define font stack used for condensed font
 * @type string
 */

$font-family-condensed          : sans-serif;
$font-family-fallback-condensed : sans-serif;

/**
 * define font stack used for monospaced copy
 * @type string
 */

$font-family-monospace        : 'Inconsolata', "Menlo", Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
                                'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L',
                                Monaco, 'Courier New', Courier, monospace;

/**
 * define font stack used for headings
 * @requires {variable} $font-family-serif
 * @type string
 */

$font-family-display          : $font-family-condensed;
$font-family-display-fallback : $font-family-fallback-condensed;

/**
 * define font stack used for paragraphs
 * @requires {variable} font-family-sans
 * @type string
 */

$font-family-default          : $font-family-sans;
$font-family-fallback         : $font-family-fallback-sans;

// Font Weights
// ============

$font-weight-light            : 300;
$font-weight-normal           : 400;
$font-weight-semibold         : 600;
$font-weight-bold             : 700;
$font-weight-heavy            : 900;

// Fonts Loaded Class (on html element)
// ============

$fonts-loaded-class : '.wf-active';

// Breakpoints
// Define your own breakpoints to suit the design
// @link http://breakpoint-sass.com/
// ============

@include breakpoint-set('to ems', true);
@include breakpoint-set('no query fallbacks', true);

$bp-middle-start: 500px; 
$bp-wide-start: 800px;
$bp-extra-wide-start: 1200px; 

$bp-narrow: max-width ($bp-middle-start - 1px);
$bp-middle: $bp-middle-start ($bp-wide-start - 1px);
$bp-wide: $bp-wide-start, 'no-query' '.lt-ie9';

$bp-narrow-and-middle: max-width ($bp-wide-start - 1px);
$bp-middle-and-wide: $bp-middle-start, 'no-query' '.lt-ie9';

$bp-wide-to-extra-wide: $bp-wide-start $bp-extra-wide-start;
$bp-extra-wide: $bp-extra-wide-start, 'no-query' '.lt-ie9';

// Navigation Breakpoints
$nav-switch-point: 680px;
$bp-nav-collapsed: max-width ($nav-switch-point - 1px);
$bp-nav-open:      $nav-switch-point, 'no-query' '.lt-ie9';

// Footer Breakpoints
$footer-switch-point: 830px;
$bp-footer-narrow: max-width ($footer-switch-point - 1px);
$bp-footer-wide:      $footer-switch-point, 'no-query' '.lt-ie9';

// CSS Box Model
// @link: http://www.paulirish.com/2012/box-sizing-border-box-ftw/
// @type string
// ===========
$box-sizing-default: border-box;

