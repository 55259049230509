/* ==========================================================================
   Tables
   ========================================================================== */

td, th {
  font-size: .8em;
  padding: .5em;
  text-align: left;
}

td {
  border-top: 1px solid $color-border;
}